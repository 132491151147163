import { useQuery } from '@tanstack/react-query';
import endpoints from '../helpers/endpoints';
import api from '../api';

export const usePermissions = () => {
    const { data, isLoading, error } = useQuery({
        queryKey: ['permissions'],
        queryFn: async () => {
            const response = await api.get(endpoints.getPermissions);
            if (response.error) {
                throw new Error('Failed to fetch permissions');
            }
            return response.data.permissions;
        },
        refetchOnWindowFocus: false,
        retry: false
    });

    return { 
        permissions: data,
        isLoadingPermissions: isLoading,
        permissionsError: error
    };
};
