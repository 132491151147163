import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Container, Button } from '../Layout';
import { gridContainerStyle } from './styles';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import i18n from '../../helpers/i18n';
import { createDevice, getEmployees, getLocationsAndCnpj } from './CreateDeviceContent.queries';
import { useFormik } from 'formik';
import { validationSchema } from './validationSchema';
import { RestrictionSection, AuthorizedCollaboratorsSection, ControllerSection, DeviceSection } from '../CreateEditSections/';
import { deviceTypes, filterTypes } from '../../helpers/consts/deviceConstants';
import { useNavigate } from 'react-router-dom';
import DeviceCreatedAlert from '../DeviceCreatedAlert/DeviceCreatedAlert';
import { useError } from '../SnackbarError/ErrorContext';
import { usePermissions } from '../../hooks/usePermissions';


const CreateDeviceContent = () => {
    const [offset, setOffset] = useState(0);
    const [byName, setByName] = useState('');
    const [employees, setEmployees] = useState([]); 
    const [locations, setLocations] = useState([]);
    const [cnpjs, setCnpjs] = useState([]);

    const navigate = useNavigate();

    const [isOpenCreateDevice, setIsOpenCreateDevice] = useState(false);
    const [activationKey, setActivationKey] = useState('');
    const [company, setCompany] = useState('');
    const [deviceType, setDeviceType] = useState('');

    const { showError } = useError();

    const [hasCnpjSet, setHasCnpjSet] = useState(false);
    // const [createdDeviceId, setCreatedDeviceId] = useState(null);

    const { data: dataEmployees, isLoading: isLoadingEmployees } = useQuery({
        queryKey: ['funcionarios', offset, byName],
        queryFn: () => getEmployees({ limit: 50, offset, byName }),
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    });

    const { data: dataLocationsAndCnpj, isLoading: isLoadingLocationsAndCnpjs } = useQuery({
        queryKey: ['locations'],
        queryFn: getLocationsAndCnpj,
        refetchOnWindowFocus: false,
        retry: false
    });

    const mutationCreateDevice = useMutation({
        mutationFn: createDevice,
        onSuccess: (data) => {

            if (!data.response.result && data.response.errorType) {
                let errorMsg = i18n.t('pages.errors.device.createDefault');
                
                // Tratamento de erros específicos que vem do pw retornando 200 como status
                switch (data.response.errorType) {
                    case 'existsName':
                        errorMsg = i18n.t('pages.errors.device.existsName');
                        break;
                    default:
                        errorMsg = i18n.t('pages.errors.device.createWithType', { errorType: data.response.errorType });
                }
                
                showError(errorMsg);
                return;
            }

            setActivationKey(data.response.activationKey);
            setCompany(data.response.company);
            setDeviceType(data.response.deviceType);
            setIsOpenCreateDevice(true);
        },
        onError: (e) => {
            showError(i18n.t('pages.errors.device.createDefault'));
        },
    });

    const handleSubmit = async (values) => {
        mutationCreateDevice.mutate(values);
    };

    const formik = useFormik({
        initialValues: {
            deviceType: '',
            cnpj: '',
            name: '',
            timezone: 'America/Sao_Paulo',
            activationKey: '',
            active: false,
            expirationDate: false,
            startPeriod: null,
            endPeriod: null,

            collaboratorsType: 'by_employee',
            employees: [],
            locations: [],

            //Crontroller
            enable_password_punch: false, //Ativar batida por matrícula e senha
            break_device: false, //Ativar dispositivo de pausa NR17
            enable_auto_update: false, //Ativar atualização automática (Tablet Ahgora)
            default_computer: false, //Ativar batida online padrão por link
            timezone_filter: false,  //Ativar fuso horário como filtro
            triple_validation: false, //Ativar validação tripla para múltiplos usuários
            enable_punches_logs: false, //ativar logs de batidas
            enable_punch_backup: false, //Ativar download de arquivo com batida de ponto (Solicitar donwload do arquivo)

            //Restriction
            online_only: false,
            auto_timezone: false,
            only_with_location: false,
            only_inside_geofence: false,
            range_ip: false,
            ips: [], //Ip Range '123.123.123.23, 123.123.123.24'
        },
        validationSchema,
        onSubmit: handleSubmit
    });

    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //     const shouldEnableDefaultComputer = formik.values.deviceType === 'my_ahgora_device'; // eslint-disable-line no-use-before-define
    //     if (formik.values.default_computer !== shouldEnableDefaultComputer) { // eslint-disable-line no-use-before-define
    //         formik.setFieldValue('default_computer', shouldEnableDefaultComputer); // eslint-disable-line no-use-before-define
    //     }
    // }, [formik.values.deviceType, formik.values.default_computer]); // eslint-disable-line react-hooks/exhaustive-deps

    const { permissions } = usePermissions();

    useEffect(() => {
        if (dataLocationsAndCnpj) {
            let locations = dataLocationsAndCnpj.locations;

            if (typeof locations === 'object' && !Array.isArray(locations)) {
                locations = Object.values(locations);
            }

            const locationArray = locations.map(location => ({ value: location, title: location }));
            const cnpjArray = dataLocationsAndCnpj.cnpjs.map(cnpj => ({ value: cnpj, title: cnpj }));

            setLocations(locationArray);
            setCnpjs(cnpjArray);

            if (!hasCnpjSet && !formik.values.cnpj && cnpjArray.length > 0) {
                formik.setFieldValue('cnpj', cnpjArray[0].value);
                setHasCnpjSet(true);
            }
        }
    }, [dataLocationsAndCnpj, formik, hasCnpjSet]);

    useEffect(() => {

        if (dataEmployees?.data) {
            const newEmployees = dataEmployees.data.map(employee => ({
                value: employee.dados.matricula,
                title: `${employee.dados.matricula} | ${employee.dados.nome}`
            }));
    
            setEmployees(prevEmployees => {
                const uniqueEmployees = newEmployees.filter(newEmployee =>
                    !prevEmployees.some(existingEmployee => existingEmployee.value === newEmployee.value)
                );
                return [...prevEmployees, ...uniqueEmployees];
            });
        }
    }, [dataEmployees, byName]); 

    const handleScroll = (event) => {
        if (isLoadingEmployees || byName) return; 
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
            setOffset(prevOffset => prevOffset + 1);
        }
    };

    const handleFilterSearch = (searchText) => {
        setByName(searchText); 
    };

    function handleCreatedDevice() {
        setIsOpenCreateDevice(false);
        navigate('/');
    }

    return (
        <Container>
            <DeviceCreatedAlert isOpen={isOpenCreateDevice} onClose={handleCreatedDevice} company={company} deviceType={deviceType} activationKey={activationKey} />
            <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
                <Grid sx={gridContainerStyle} container spacing={10}>
                    <DeviceSection
                        formik={formik}
                        cnpjs={cnpjs}
                        deviceTypes={deviceTypes}
                        isLoadingLocationsAndCnpjs={isLoadingLocationsAndCnpjs} />

                    <AuthorizedCollaboratorsSection
                        formik={formik}
                        employees={employees} 
                        locations={locations}
                        isLoadingLocationsAndCnpjs={isLoadingLocationsAndCnpjs}
                        handleFilterSearch={handleFilterSearch}
                        handleScroll={handleScroll}
                        isLoadingEmployees={isLoadingEmployees}
                        filterTypes={filterTypes}
                    />

                    <ControllerSection formik={formik} permissions={permissions} />

                    {permissions?.permite_restricao_batida && (
                        <RestrictionSection formik={formik} permissions={permissions}/>
                    )}
                </Grid>
                <Divider style={{ marginBottom: 16 }} />
                <Button type="outlined"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/');
                    }}
                >{i18n.t('pages.newDevice.cancel')}</Button>
                <Button type="submit">{i18n.t('pages.newDevice.createDevice')}</Button>
            </form>
        </Container>
    );
};

export default CreateDeviceContent;
