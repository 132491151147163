import React, { useState } from "react";
import { CloudDownload, CopyAll, ViewWeek, Print, Archive } from '@mui/icons-material';
import { IconButton, Tooltip, Menu, Toolbar as MuiToolbar, TextField } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import { MenuItemStyled } from "./styles";
import exportFile from '../../helpers/functions/exportFile';
import i18n from "../../helpers/i18n";

const Toolbar = ({ selectedData, onSearch, onChangeColumns, columns, onToggleArchived, isShowArchived
 }) => {
    const [searchText, setSearchText] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [columnsMenuOpen, setColumnsMenuOpen] = useState(false);
    const [exportMenuOpen, setExportMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleChange = (event) => {
        const text = event.target.value;
        setSearchText(text);
        onSearch(text);
    };

    const toggleSearch = () => {
        setIsSearchActive(!isSearchActive);
        if (isSearchActive) {
            onSearch('');
        }
    };

    const handleMenuExportOpen = (event) => {
        setExportMenuOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuColumnsOpen = (event) => {
        setColumnsMenuOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuExportClose = () => {
        setExportMenuOpen(false);
        setAnchorEl(null);
    };

    const handleMenuColumnsClose = () => {
        setColumnsMenuOpen(false);
        setAnchorEl(null);
    };

    const handleExport = (type, selectedColumns) => {
        const columnsTable = selectedColumns.map(column => ({ name: column.field, label: column.headerName }));
        const dataToExport = selectedData.map(row => {
            const rowData = {};
            selectedColumns.forEach(column => {
                rowData[column.field] = row[column.field];
            });
            return rowData;
        });
        exportFile(columnsTable, dataToExport, type);
        handleMenuExportClose();
    };

    const copyDevicesToClipboard = () => {
        const formattedData = selectedData.map(row =>
            `${row.dispositivo}\t${row.tipoDispositivo}\t${row.status}\t${row.dataAtivacao}\t${row.dataSincronia}\t${row.chave}`
        ).join('\n');
        navigator.clipboard.writeText(formattedData);
    };

    const handleColumnToggle = (columnIndex) => {
        const updatedColumns = [...columns];
        updatedColumns[columnIndex].checked = !updatedColumns[columnIndex].checked;
        onChangeColumns(updatedColumns);
    };

    const handlePrint = () => {
        window.print();
    };

    const getCheckedColumns = () => {
        return columns.filter(column => column.checked && column.field !== 'ações');
    };

    const searchInterface = (
        <>
            <Tooltip title={i18n.t('toolBar.search')}>
                <IconButton sx={{ padding: '12px' }} onClick={toggleSearch} aria-label={i18n.t('toolBar.search')}>
                    <GridSearchIcon />
                </IconButton>
            </Tooltip>
            {isSearchActive && (
                <TextField
                    sx={{
                        '& .MuiInputBase-input::placeholder': {
                            font: 'normal normal 400 14px/24px Open Sans',
                        },
                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                            borderBottom: 'solid 1px var(--ahg-gray-darken1)',
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: 'solid 1px var(--ahg-gray-darken1)',
                        },
                        '& .MuiInput-underline:after': {
                            borderBottom: 'solid 1px var(--ahg-gray-darken1)',
                        },
                        '& .MuiSelect-select:focus': {
                            backgroundColor: 'transparent',
                        },
                        '& .MuiSelect-underline:before': {
                            borderBottom: 'solid 1px var(--ahg-gray-darken1)',
                        },
                        '& .MuiSelect-underline:hover:not(.Mui-disabled):before': {
                            borderBottom: 'solid 1px var(--ahg-gray-darken1)',
                        },
                        '& .MuiSelect-underline:after': {
                            borderBottom: 'solid 1px var(--ahg-gray-darken1)',
                        }
                    }}
                    value={searchText}
                    onChange={handleChange}
                    placeholder={i18n.t('toolBar.search')}
                    variant="standard"
                    size="small"
                    margin="none"
                />
            )}
        </>
    );

    return (
        <MuiToolbar sx={{
            borderBottomStyle: 'none',
            backgroundColor: '#fff',
            paddingTop: '8px',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            textAlign: 'right',
            alignSelf: 'stretch',
            justifyContent: 'flex-end',
            zIndex: 2,
        }}>
            {searchInterface}
            <Tooltip title={i18n.t('toolBar.copyLines')}>
                <IconButton sx={{ padding: '12px' }} aria-label={i18n.t('toolBar.copyLines')} onClick={copyDevicesToClipboard}>
                    <CopyAll />
                </IconButton>
            </Tooltip>
            <Tooltip title={i18n.t('toolBar.showColumns')}>
                <IconButton sx={{ padding: '12px' }} aria-label={i18n.t('toolBar.showColumns')} onClick={handleMenuColumnsOpen}>
                    <ViewWeek />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={columnsMenuOpen}
                onClose={handleMenuColumnsClose}
            >
                {columnsMenuOpen && columns.map((column, index) => (
                    <MenuItemStyled key={index}>
                        <label>
                            <input
                                aria-label={`${column.headerName}Checkbox`}
                                type="checkbox"
                                checked={column.checked}
                                onChange={() => handleColumnToggle(index)}
                            />  {column.headerName}
                        </label>
                    </MenuItemStyled>
                ))}
            </Menu>
            <Tooltip title={i18n.t('toolBar.print')}>
                <IconButton sx={{ padding: '12px' }} aria-label={i18n.t('toolBar.print')} onClick={handlePrint}>
                    <Print />
                </IconButton>
            </Tooltip>
            <Tooltip title={i18n.t('toolBar.export.export')}>
                <IconButton sx={{ padding: '12px' }} aria-label={i18n.t('toolBar.export.export')} onClick={handleMenuExportOpen}>
                    <CloudDownload />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={exportMenuOpen}
                onClose={handleMenuExportClose}
            >
                <MenuItemStyled onClick={() => handleExport('csv', getCheckedColumns())}>{i18n.t('toolBar.export.csv')}</MenuItemStyled>
                <MenuItemStyled onClick={() => handleExport('xlsx', getCheckedColumns())}>{i18n.t('toolBar.export.xlsx')}</MenuItemStyled>
                <MenuItemStyled onClick={() => handleExport('pdf', getCheckedColumns())}>{i18n.t('toolBar.export.pdf')}</MenuItemStyled>
            </Menu>
            <Tooltip title={i18n.t('toolBar.showDeletedDevice')}>
                <IconButton 
                    sx={{ padding: '12px', backgroundColor: isShowArchived ? 'var(--ahg-waterloo-lighten4)' : 'inherit' }} 
                    aria-label={i18n.t('toolBar.showDeletedDevice')} 
                    onClick={onToggleArchived}>
                    <Archive />
                </IconButton>
            </Tooltip>
        </MuiToolbar>
        
    );
}

export default Toolbar;
