import React, { useEffect } from 'react';
import { Cancel } from "@mui/icons-material";
import { SnackbarStyled } from "./styles";
import { IconButton } from "@mui/material";
import { useError } from './ErrorContext'; 

export default function SnackbarError() {
  const { error, clearError } = useError(); 
  const isOpen = Boolean(error); 

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        clearError();
      }, 6000); // 

      return () => clearTimeout(timer); 
    }
  }, [isOpen, clearError]);

  const handleClose = () => {
    clearError(); 
  };

  return (
    <SnackbarStyled
      open={isOpen}
      autoHideDuration={6000} 
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      message={error} 
      action={
        <IconButton aria-label="closeError" sx={{ color: 'white' }} onClick={handleClose}>
          <Cancel />
        </IconButton>
      }
    >
    </SnackbarStyled>
  );
}
