import dayjs from 'dayjs';
import api from '../../api';
import consts from '../../helpers/consts';

function mapperDeviceData(deviceData) {
  let startPeriodFormated = ''
  let endPeriodFormated = ''
  if (deviceData.expirationDate) {
    startPeriodFormated = dayjs(deviceData.startPeriod).format('YYYY-MM-DD');
    endPeriodFormated = dayjs(deviceData.endPeriod).format('YYYY-MM-DD');
  }
  
  let default_computer = deviceData.default_computer ? '1' : '0';
  let active = deviceData.active ? '1' : '0';

  if (deviceData.deviceType === 'my_ahgora_device' ) {
    default_computer = '1'
    active = '1'
  }
  
  return {
    active: active,
    name: deviceData.name,
    timezone: deviceData.timezone,
    device_type: deviceData.deviceType,
    startPeriod: startPeriodFormated,
    endPeriod: endPeriodFormated,
    activationKey: deviceData.activationKey.toString() || '',
    enable_password_punch: deviceData.enable_password_punch ? '1' : '0',
    // save_photo_on_punch	?
    timezone_filter: deviceData.timezone_filter ? '1' : '0',
    break_device: deviceData.break_device ? '1' : '0',
    enable_auto_update: deviceData.enable_auto_update ? '1' : '0',
    default_computer: default_computer,
    triple_validation: deviceData.triple_validation ? 'on' : 'off',
    enable_punches_logs: deviceData.enable_punches_logs ? '1' : '0',
    enable_punch_backup: deviceData.enable_punch_backup ? '1' : '0',
    online_only: deviceData.online_only ? '1' : '0',
    auto_timezone: deviceData.auto_timezone ? '1' : '0',
    only_with_location: deviceData.only_with_location ? '1' : '0',
    only_inside_geofence: deviceData.only_inside_geofence ? '1' : '0',
    range_ip: deviceData.ips.join(', '),

    optionEmployeesFilter: deviceData.collaboratorsType === 'by_employee' ? '1' : '0',

    cnpj: deviceData.cnpj,
    employees: deviceData.employees,
    locations: deviceData.locations,
  }
}


function mapperMultipleDeviceData(deviceData) {
  let startPeriodFormated = ''
  let endPeriodFormated = ''
  if (deviceData.expirationDate) {
    startPeriodFormated = dayjs(deviceData.startPeriod).format('YYYY-MM-DD');
    endPeriodFormated = dayjs(deviceData.endPeriod).format('YYYY-MM-DD');
  }

  return {
    timezone_device: deviceData.timezone,
    device_type: deviceData.deviceType,
    startPeriodMultiple: startPeriodFormated,
    endPeriodMultiple: endPeriodFormated,
    cnpj: deviceData.cnpj,
    company: deviceData.company,
  }
}


export const createDevice = async (deviceData) => {
  const payload = mapperDeviceData(deviceData);
  console.log('Payload:', payload);

  try {
    const response = await api.post(`${consts.BASE_PATH_API}batidaonline/setRegistration`, payload);

    if (!response) {
      throw new Error('Não foi possível criar o dispositivo');
    }

    // Ensure we have the required data
    if (!response.identity) {
      console.error('No identity in create response:', response);
      throw new Error('ID do dispositivo não retornado pelo servidor');
    }

    // Check for activation key in different possible response formats
    const activationKey = response.hash;
    let deviceType = deviceData.deviceType;

    // Check if deviceType is 'ahgora_online_device' and default_computer is true
    if (deviceData.deviceType === 'ahgora_online_device' && deviceData.default_computer) {
      deviceType = 'ahgora_online_device_default'
    }

    return {
      response: {
        deviceType: deviceType,
        identity: response.identity,
        activationKey: activationKey,
        company: response.company
      }
    };

  } catch (error) {
    console.error('Error in createDevice:', error);
    throw new Error(`Erro ao criar dispositivos: ${error.message}`);
  }
}

export const createMultipleDevices = async (deviceData) => {
  const payload = mapperMultipleDeviceData(deviceData);
  try {
    const response = await api.post(`${consts.BASE_PATH_API}batidaonline/generateMultiActivationCodes`, payload);
    if (!response) {
      throw new Error('Não foi possível criar multiplos dispositivos');
    }
    return {
      ...response
    };
  } catch (error) {
    throw new Error(`Erro ao criar multiplos dispositivos: ${error.message}`);
  }
}

export const editDevice = async ({ identity, deviceData }) => {
  const payload = mapperDeviceData(deviceData);
  try {
    const response = await api.post(`${consts.BASE_PATH_API}batidaonline/setRegistration`, { ...payload, identity });
    if (!response) {
      throw new Error('Não foi possível criar o dispositivo');
    }
    return {
      response
    };
  } catch (error) {
    throw new Error(`Erro ao criar dispositivos: ${error.message}`);
  }
}



export const getEmployees = async ({ limit, offset, byName }) => {
  try {

    // Base URL
    const baseUrl = `${consts.BASE_PATH_API}api/funcionarios?status=true&employeesByUser=true&filter[sort]=a-z`;

    // Verifica se 'byName' não está vazio. Se não estiver, monta a URL sem 'limit' e 'offset'
    const url = byName?.trim()
      ? `${baseUrl}&byName=${byName}`  // Se 'byName' for válido, usa a URL sem 'limit' e 'offset'
      : `${baseUrl}&limit=${limit}&offset=${offset}`;  // Caso contrário, inclui 'limit' e 'offset'

    const response = await api.get(url);

    if (response.error) {
      throw new Error('Não foi possível carregar os funcionários');
    }
    return {
      data: response.data
    };
  } catch (error) {
    throw new Error(`Erro ao carregar os funcionários: ${error.message}`);
  }
}

export const getLocationsAndCnpj = async () => {
  try {
    const response = await api.get(`${consts.BASE_PATH_API}api/empresas/getDevicesPermissions`);
    if (response.error) {
      throw new Error('Não foi possível carregar os funcionários');
    }
    return {
      locations: response.data.locations,
      cnpjs: response.data.cnpj,
      company: response.data.company
    };
  } catch (error) {
    throw new Error(`Erro ao carregar os funcionários: ${error.message}`);
  }
}

export const getDevice = async ({ identity, existingEmployees = [] }) => {
  try {

    const url = `${consts.BASE_PATH_API}batidaonline/getRegistration?identity=${encodeURIComponent(identity)}`;

    // Add a small delay before fetching
    await new Promise(resolve => setTimeout(resolve, 500));

    // Make sure we're not sending any body with the GET request
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }).then(res => res.json());

    // Check if response exists and has data
    if (!response) {
      console.error('No response received from API');
      throw new Error('Não foi possível carregar o dispositivo - Sem resposta do servidor');
    }

    // If the response has a data property, use that
    const responseData = response.data || response;

    // Transform the response data
    const deviceData = {
      ...responseData,
      name: responseData.name ? responseData.name.replace(/&nbsp;/g, "").replace(/&nbsp/g, "") : "",
      active: Boolean(responseData.active),
      deviceType: responseData.device_type || '',
      timezone: responseData.timezone || '',
      cnpj: responseData.cnpj || '',
      employees: responseData.employees || [],
      locations: Array.isArray(responseData.locations) ? responseData.locations : [],
      ips: responseData.range_ip ? responseData.range_ip.split(',').map(ip => ip.trim()) : [],
      collaboratorsType: responseData.optionEmployeesFilter === '1' ? 'by_employee' : 'by_location'
    };

    // If validation is really needed and we have existing employee data
    if (deviceData.employees.length > 0 && existingEmployees.length > 0) {
      deviceData.employees = deviceData.employees.filter(empId =>
        existingEmployees.some(emp => String(emp.dados.matricula) === String(empId))
      );
    }

    return deviceData;

  } catch (error) {
    console.error('Error fetching device:', error);
    console.error('Error details:', {
      message: error.message,
      stack: error.stack,
      response: error.response
    });
    throw new Error(`Erro ao carregar dispositivo: ${error.message}`);
  }
}

