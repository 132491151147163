import { styled } from '@mui/system';
import { Snackbar } from '@mui/material';

export const SnackbarStyled = styled(Snackbar)(() => ({
    fontFamily: 'Open Sans',
    color: 'var(--ahg-secondary-lighten5)',
    maxWidth: '440px' , 
    display: 'flex',  
    flexDirection: 'row',  
    justifyContent: 'space-between', 
    alignItems: 'center', 
    '.MuiSnackbarContent-root': {
        borderBottom: 'solid 6px #DD1818' , 
        backgroundColor: 'var(--ahg-waterloo-darken1)', 
    },
    '.MuiSnackbarContent-message': {
        fontSize: '14px', 
        fontWeight: '600',
        paddingRight: '30px'
    },
    '.MuiSnackbarContent-action': {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 0,
    }
}));
