import { styled } from '@mui/system';

export const datePickerStyles = {
    marginTop: '16px !important',
    marginLeft: '16px !important',
    width: 'calc(100% - 32px)',
    fontSize: 12,
    fontFamily: 'Open Sans',
    '& .MuiInputBase-root': {
        fontFamily: 'Open Sans',
    },
    '& .MuiButtonBase-root': {
        color: '#2D9CDB',
        fontFamily: 'Open Sans'
    },
    '& .MuiPickersDay-root': {
        fontFamily: 'Open Sans',
    },
    '& .MuiPickersCalendarHeader-labelContainer': {
        fontFamily: 'Open Sans',
    },
    '& .MuiPickersYear-yearButton': {
        fontFamily: 'Open Sans',
    },
    '& .MuiPickersMonth-root': {
        fontFamily: 'Open Sans',
    },
    '& .MuiPickersSlideTransition-root': {
        fontFamily: 'Open Sans',
    },
    '& .MuiPickersToolbar-root': {
        fontFamily: 'Open Sans',
    },
    '& .MuiPickersToolbarText-toolbarTxt': {
        fontFamily: 'Open Sans',
    },
    '& .MuiPickersClock-pin': {
        fontFamily: 'Open Sans',
    },
    '& .MuiPickersClockPointer-pointer': {
        fontFamily: 'Open Sans',
    },
    '& .MuiPickersClockPointer-thumb': {
        fontFamily: 'Open Sans',
    },
    '& .MuiPickersClockNumber-clockNumber': {
        fontFamily: 'Open Sans',
    },
    '& .MuiPickersClockNumber-clockNumberSelected': {
        fontFamily: 'Open Sans',
    },
};

export const DatePickerWrapper = styled('div')(datePickerStyles);
