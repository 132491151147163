import AlertModal from '../AlertModal';
import { Typography } from '../Layout';
import CopyButton from '../CopyButton';
import consts from '../../helpers/consts';
import i18n from '../../helpers/i18n';

export default function DeviceCreatedAlert({ isOpen, onClose, deviceType, activationKey, company }) {

    const batidaOnlineDefaultURL = `${consts.BASE_PATH_API}novabatidaonline/?defaultDevice=${company}`
    const batidaOnlineURL = `${consts.BASE_PATH_API}novabatidaonline/`


    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    return (
        <AlertModal
            title={i18n.t('modal.deviceCreated.title')}
            buttonText={i18n.t('modal.deviceCreated.buttonText')}
            buttonAction={handleClose}
            visible={isOpen}
            children={
                <>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{i18n.t('modal.deviceCreated.congratulations')}</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'regular', fontStyle: 'normal' }}>{i18n.t('modal.deviceCreated.successMessage')}</Typography>
                    {deviceType !== 'my_ahgora_device' && deviceType !== 'ahgora_online_device_default' && (
                        <div style={{
                            padding: '20px 0px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            gap: deviceType === 'ahgora_multi_device' ? '0px' : '20px'
                        }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', fontStyle: 'normal' }}>
                                {i18n.t('modal.deviceCreated.authorizationCode')}
                            </Typography>
                            <CopyButton value={activationKey} />
                        </div>
                    )}
                    {deviceType === 'ahgora_multi_device' &&
                        <Typography variant="body2" sx={{ fontWeight: 'regular', fontStyle: 'normal' }}>{i18n.t('modal.deviceCreated.multiInstructions1')} <strong>{i18n.t('modal.deviceCreated.multiInstructions2')}</strong>
                            {i18n.t('modal.deviceCreated.multiInstructions3')}
                        </Typography>
                    }
                    {deviceType === 'ahgora_online_device' &&
                        <Typography variant="body2" sx={{ fontWeight: 'regular', fontStyle: 'normal' }}>{i18n.t('modal.deviceCreated.onlineInstructions1')}{' '}
                            <strong>{i18n.t('modal.deviceCreated.onlineInstructions2')}</strong>{' '}{i18n.t('modal.deviceCreated.onlineInstructions3')}{' '}
                            <a href={`${batidaOnlineURL}`} target="_blank" rel="noreferrer" style={{ color: "var(--ahg-waterloo-darken1)" }}>Batida Online</a> {i18n.t('modal.deviceCreated.onlineInstructions4')}
                        </Typography>
                    }
                     {deviceType === 'ahgora_online_device_default' &&
                        <Typography variant="body2" sx={{ fontWeight: 'regular', fontStyle: 'normal', paddingTop: '20px'}}>{i18n.t('modal.deviceCreated.defaultInstructions1')}
                            <a href={`${batidaOnlineDefaultURL}`} target="_blank" rel="noreferrer" style={{ color: "var(--ahg-waterloo-darken1)" }}>Batida Online</a>.
                        </Typography>
                    }
                </>}
        />
    );
}