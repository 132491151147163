import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { datePickerStyles } from './styles';
import i18n from '../../helpers/i18n';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import { enUS, ptBR, esES } from '@mui/x-date-pickers/locales';

const localeMap = {
  'pt-BR': { locale: 'pt-br', muiLocale: ptBR },
  'en-US': { locale: 'en', muiLocale: enUS },
  'es-ES': { locale: 'es', muiLocale: esES },
};

const getLocale = () => {
  const browserLocale = navigator.language;
  console.log('Browser locale:', browserLocale);
  return localeMap[browserLocale] || localeMap['pt-BR'];
};

const FilterDate = ({ filterDate, setFilterDate }) => {
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const { locale, muiLocale } = getLocale();

  useEffect(() => {
    console.log('Locale being used:', locale);
    dayjs.locale(locale);
  }, [locale]);

  return (
    <LocalizationProvider 
      dateAdapter={AdapterDayjs} 
      localeText={muiLocale.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <DesktopDatePicker
        format="DD/MM/YYYY"
        sx={datePickerStyles}
        value={filterDate.start ? dayjs(filterDate.start) : null}
        onChange={(val) => setFilterDate({ ...filterDate, start: val })}
        open={openStart}
        onOpen={() => setOpenStart(true)}
        onClose={() => setOpenStart(false)}
        slotProps={{
          textField: {
            onClick: () => setOpenStart(true),
            placeholder: i18n.t('filter.from'),
            fullWidth: true,
          },
        }}
      />

      <DesktopDatePicker
        format="DD/MM/YYYY"
        sx={datePickerStyles}
        value={filterDate.end ? dayjs(filterDate.end) : null}
        onChange={(val) => setFilterDate({ ...filterDate, end: val })}
        open={openEnd}
        onOpen={() => setOpenEnd(true)}
        onClose={() => setOpenEnd(false)}
        slotProps={{
          textField: {
            onClick: () => setOpenEnd(true),
            placeholder: i18n.t('filter.to'),
            fullWidth: true,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default FilterDate;