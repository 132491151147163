import React, { useState, useEffect, useRef } from 'react';
import { TextField, ListSubheader, InputAdornment } from '@mui/material';
import { CustomSelect, Label, Form, MenuItemStyled } from './styles';
import SearchIcon from "@mui/icons-material/Search";
import i18n from '../../../helpers/i18n';

const Select = ({ enableSearch = false, ...props }) => {
    const [searchText, setSearchText] = useState('');
    const [filteredItems, setFilteredItems] = useState(props.items);
    const searchInputRef = useRef(null);

    const normalizeText = (text) => {
        return text
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');
    };
    useEffect(() => {
        if (enableSearch) {
            const filtered = props.items.filter(item => 
                normalizeText(item.title).includes(normalizeText(searchText))
            );
            setFilteredItems(filtered);
        } else {
            setFilteredItems(props.items);
        }
    }, [searchText, props.items, enableSearch]);

    const handleLabelHover = (action) => {
        const el = document.getElementById(`${props.id}-label`);
        if(action === 'enter') {
            el.classList.add('input-hovered');
        } else {
            el.classList.remove('input-hovered');
        }
    }

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const handleOpen = () => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    return (
        <Form>
            <Label id={`${props.id}-label`} disabled={props.disabled}>{props.title}</Label>
            <CustomSelect 
                id={props.id} 
                labelId={`${props.id}-label`} 
                onMouseEnter={() => handleLabelHover('enter')} 
                onMouseLeave={() => handleLabelHover('leave')} 
                disabled={props.disabled}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                error={props.error}
                MenuProps={{
                    autoFocus: false,
                    PaperProps: {
                        onKeyDown: handleKeyDown,
                        sx: {
                            '& .MuiMenuItem-root': {
                                fontFamily: 'Open Sans',
                                fontSize: '14px',
                            }
                        }
                    }
                }}
                onOpen={handleOpen}
            >
                {enableSearch && (
                    <ListSubheader sx={{paddingLeft: 0}}>
                        <TextField
                            sx={{ 
                                m: '8px',
                                '& .MuiInputBase-input': {
                                    fontSize: '14px',
                                    fontFamily: 'Open Sans',
                                    color: 'var(--ahg-waterloo-darken1)'
                                },
                                '& .MuiInputLabel-root': {
                                    fontSize: '14px',
                                    fontFamily: 'Open Sans',
                                    color: 'var(--ahg-waterloo-darken1)'
                                },
                                '& .MuiFormHelperText-root': {
                                    fontSize: '12px',
                                    fontFamily: 'Open Sans',
                                    color: 'var(--ahg-waterloo-darken1)'
                                }
                            }}
                            ref={searchInputRef}
                            autoFocus
                            fullWidth
                            value={searchText}
                            onChange={handleSearchChange}
                            placeholder={i18n.t('toolBar.search')}
                            variant="outlined"
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                    e.stopPropagation();
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </ListSubheader>
                )}
                {filteredItems.map(item => 
                    <MenuItemStyled key={item.value} value={item.value}>
                        {item.title}
                    </MenuItemStyled>
                )}
            </CustomSelect>
        </Form>
    );
}

export default Select;