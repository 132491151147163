
import api from '../../api';
import consts from '../../helpers/consts';
import endpoints from '../../helpers/endpoints';

export const fetchDevices = async () => {
  try {
    const response = await api.get(`${consts.BASE_PATH_API}batidaonline/makeTable`);
    
    if (!response.aaData) {
      throw new Error('Dados não encontrados na resposta da API');
    }

    const rows = response.aaData.map((device) => {
        const dispositivo = device[0].replace(/&nbsp;/g, "").replace(/&nbsp/g, "");
        const status = device[1].match(/<span.*?>(.*?)<\/span>/)[1] === 'Ativo' ? 'ATIVO' : 'INATIVO';
        let tipoDispositivo = device[8]
        switch (device[8]) {
            case 'ahgora_multi_device':
                tipoDispositivo = 'Multi';
                break;
            case 'my_ahgora_device':
                tipoDispositivo = 'My Ahgora';
                break;
            case 'ahgora_biometric_device':
                tipoDispositivo = 'Biometria';
                break;
            case 'ahgora_online_device':
                tipoDispositivo = 'Batida online';
                break;
            case 'ahgora_online_device_default':
                tipoDispositivo = 'Batida Online (padrão)';
                break;
            case null:
                tipoDispositivo = '';
            break
            default:
                tipoDispositivo = device[8]
                break;
        }

      return {
        dispositivo,
        status,
        dataCadastro: device[2],
        dataAtivacao: device[3],
        dataSincronia: device[4],
        chave: device[5],
        id: device[7],
        tipoDispositivo,
        archived: device[9] || false
      };
    });
    return rows;
  } catch (error) {
    throw new Error(`Erro ao buscar dados: ${error.message}`);
  }
};

export const fetchArchivedDevices = async () => {
  try {
    const response = await api.get(`${consts.BASE_PATH_API}batidaonline/makeTableArchived`);
    
    if (!response.aaData) {
      throw new Error('Dados não encontrados na resposta da API');
    }

    const archivedDevices = response.aaData.map((device) => {
      const dispositivo = device[0].replace(/&nbsp;/g, "").replace(/&nbsp/g, "");
      const status = device[1].match(/<span.*?>(.*?)<\/span>/)[1] === 'Ativo' ? 'ATIVO' : 'INATIVO';
      let tipoDispositivo = device[8];
      switch (device[8]) {
        case 'ahgora_multi_device':
          tipoDispositivo = 'Multi';
          break;
        case 'my_ahgora_device':
          tipoDispositivo = 'My Ahgora';
          break;
        case 'ahgora_biometric_device':
          tipoDispositivo = 'Biometria';
          break;
        case 'ahgora_online_device':
          tipoDispositivo = 'Batida online';
          break;
        case 'ahgora_online_device_default':
          tipoDispositivo = 'Batida Online (padrão)';
          break;
        case null:
          tipoDispositivo = '';
          break;
        default:
          tipoDispositivo = device[8];
          break;
      }

      return {
        dispositivo,
        status,
        dataCadastro: device[2],
        dataAtivacao: device[3],
        dataSincronia: device[4],
        chave: device[5],
        id: device[7],
        tipoDispositivo,
        archived: device[9] || false,
      };
    });

    return archivedDevices;
  } catch (error) {
    throw new Error(`Erro ao buscar dados: ${error.message}`);
  }
};


export const removeRegistration = async (identity) => {
  const payload = {identity};
  try {
    const response = await api.post(`${consts.BASE_PATH_API}batidaonline/removeRegistration`, payload);
    if (!response) {
      throw new Error('Não foi possível arquivar o dispositivo');
    }
    return {
      response,
      identity
    };
  } catch (error) {
    throw new Error(`Erro ao arquivar dispositivos: ${error.message}`);
  }
}


export const downloadAFD = async ({ identity, mes, ano, cnpj }) => {
  try {
    const params = new URLSearchParams({
      identity,
      mes: mes ?? "",    
      ano: ano ?? "",    
      cnpj: cnpj ?? "" 
    }).toString();
    
    const response = await api.post(`${endpoints.getAfd}?${params}`);
    
    if (!response || response.err) {
      throw new Error('Não foi possível fazer o download da AFD');
    }
    
    return response;
  } catch (error) {
    throw new Error(`Erro ao fazer download da AFD: ${error.message}`);
  }
};
