import { styled } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';

export const MuiToolbar = styled('div')({
    width: '100%',
    borderBottomStyle: 'none',
    backgroundColor: '#fff',
    paddingTop: '8px',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    textAlign: 'right',
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    zIndex: 2,
});

export const TextFieldSearch = styled('input')({
    font: 'normal normal 400 14px/24px Open Sans',
    borderBottom: 'solid 1px var(--ahg-gray-darken1)',
    '&::placeholder': {
        font: 'normal normal 400 14px/24px Open Sans',
    },
    '&:hover:not(:disabled):before': {
        borderBottom: 'solid 1px var(--ahg-gray-darken1)',
    },
    '&:before': {
        borderBottom: 'solid 1px var(--ahg-gray-darken1)',
    },
    '&:after': {
        borderBottom: 'solid 1px var(--ahg-gray-darken1)',
    },
    '&:focus': {
        backgroundColor: 'transparent',
    }
});

export const MenuItemStyled = styled(MenuItem)({
    padding: '8px 16px',
    fontSize: 14,
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    lineHeight: '24px',
    color: 'var(--ahg-waterloo-darken1)'
});
