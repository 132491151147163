import { Button, Dialog, DialogTitle, styled, DialogContent, DialogActions } from '@mui/material';

export const PrimaryButton = styled(Button)({
    backgroundColor: 'var(--ahg-primary-base)',
    color: 'var(--ahg-gray-lighten5)',
    textTransform: 'capitalize',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    cursor: 'pointer',
    pointerEvents: 'auto',
    zIndex: 1300,
    '&:hover': {
        backgroundColor: 'var(--ahg-primary-lighten1)'
    }
});

export const TextButton = styled(Button)({
    textTransform: 'capitalize',
    color: 'var(--ahg-primary-base)',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    cursor: 'pointer',
    pointerEvents: 'auto',
    zIndex: 1300,
    '&:hover': {
        textDecoration: 'underline',
        backgroundColor: 'transparent'
    }
});

export const Modal = styled(Dialog)({
    minWidth: '600px',
    '& .MuiDialog-paper': {
        pointerEvents: 'auto',
    }
});

export const ModalBody = styled(DialogContent)({
    padding: '16px 24px',
    fontSize: 18,
    fontFamily: 'Fira Sans',
    fontStyle: 'italic',
    fontWeight: '400',
    letterSpacing: 0.30,
    wordWrap: 'break-word',
    color: 'var(--ahg-waterloo-darken1)',
});

export const ModalTitle = styled(DialogTitle)({
    fontFamily: 'Fira Sans',
    fontSize: '20px',
    fontWeight: 400,
    textAlign: 'left',
    padding: '16px 24px',
    color: 'var(--ahg-h2-modal)'
});

export const ModalFooter = styled(DialogActions)({
    padding: '16px 24px',
    pointerEvents: 'auto',
});