import React, { useState, useEffect, useRef } from 'react';
import { MenuItem, TextField, ListSubheader, Checkbox, ListItemText, CircularProgress, InputAdornment } from '@mui/material';
import { CustomSelect, Label, Form } from './styles';
import SearchIcon from "@mui/icons-material/Search";
import i18n from '../../../helpers/i18n';

const SelectMultiple = ({ ...props }) => {
    const [searchText, setSearchText] = useState('');
    const { items, handleFilterSearch } = props;
    const [itensFiltred, setItensFiltred] = useState(items);
    const searchInputRef = useRef(null);

    const normalizeText = (text) => {
        return text
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');
    };

    useEffect(() => {
        const filteredItems = items.filter(item => 
            normalizeText(item.title).includes(normalizeText(searchText))
        );
        
        setItensFiltred(filteredItems);
    }, [searchText, items]);

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
        if (handleFilterSearch) {
            const debounceTimeout = setTimeout(() => {
                handleFilterSearch(event.target.value); 
            }, 1200);

            return () => clearTimeout(debounceTimeout); 
        }
    };

    const handleLabelHover = (action) => {
        const el = document.getElementById(`${props.id}-label`);
        if (action === 'enter') {
            el.classList.add('input-hovered');
        } else {
            el.classList.remove('input-hovered');
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === 'Backspace') {
            event.preventDefault();
        }
    };

    const handleOpen = () => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    return (
        <Form>
            <Label id={`${props.id}-label`} disabled={props.disabled}>{props.title}</Label>
            <CustomSelect
                multiple
                id={props.id}
                labelId={`${props.id}-label`}
                onMouseEnter={() => handleLabelHover('enter')}
                onMouseLeave={() => handleLabelHover('leave')}
                disabled={props.disabled}
                value={props.value}
                onChange={props.onChange}
                renderValue={props.renderValue}
                label={props.label}
                MenuProps={{
                    autoFocus: false,
                    PaperProps: {
                        onScroll: props.handleScroll,
                        onKeyDown: handleKeyDown, 
                    }
                }}
                onOpen={handleOpen}
            >
                <ListSubheader sx={{paddingLeft: 0}}>
                    <TextField
                        sx={{
                            m: '8px',
                            '& .MuiInputBase-input': {
                                fontSize: '14px',
                                fontFamily: 'Open Sans',
                                color: 'var(--ahg-waterloo-darken1)'
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: '14px',
                                fontFamily: 'Open Sans',
                                color: 'var(--ahg-waterloo-darken1)'
                            },
                            '& .MuiFormHelperText-root': {
                                fontSize: '12px',
                                fontFamily: 'Open Sans',
                                color: 'var(--ahg-waterloo-darken1)'
                            }
                        }}
                        ref={searchInputRef}
                        autoFocus
                        fullWidth
                        value={searchText}
                        onChange={handleSearchChange}
                        placeholder={props.placeholder || i18n.t('toolBar.search')}
                        variant="outlined"
                        onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </ListSubheader>

                {itensFiltred.map((item) => (
                    <MenuItem 
                        key={item.value}
                        value={item.value}
                        sx={{
                            '& .MuiListItemText-primary': {
                                fontFamily: 'Open Sans',
                                fontSize: '14px',
                                color: 'var(--ahg-waterloo-darken1)'
                            }
                        }}>
                        <Checkbox checked={props.value.indexOf(item.value) > -1} />
                        <ListItemText primary={item.title} />
                    </MenuItem>
                ))}
                {props.loading && (
                    <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} disabled>
                        <CircularProgress size={24} />
                    </MenuItem>
                )}
            </CustomSelect>
        </Form>
    );
};

export default SelectMultiple;
