import React, { useEffect, useState, useMemo } from 'react';
import { Archive, Edit, Save } from '@mui/icons-material'
import { IconButton, FormControl, Grid, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import DeviceDeleteAlert from '../DeviceDeleteAlert/DeviceDeleteAlert';
import { StyledTypography } from './styles';
import Radio from '../Layout/Radio/Radio';
import Select from '../Layout/Select/Select';
import AlertModal from '../AlertModal/AlertModal';
import { downloadAFD } from '../DeviceTable/DeviceTable.queries'
import { getLocationsAndCnpj } from '../CreateDeviceContent/CreateDeviceContent.queries';
import { useQuery, useMutation } from '@tanstack/react-query';
import i18n from '../../helpers/i18n';
import { useError } from '../SnackbarError/ErrorContext';

function ActionButtons({ id, isShowArchived, onDeleteDevice, nomeComputador, identity }) {
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isOpenSaveModal, setIsOpenSaveModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState('option1');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedCNPJ, setSelectedCNPJ] = useState('');
    const [cnpjs, setCnpjs] = useState();

    const { showError } = useError();

    const onOpenDeleteModal = () => {
      setIsOpenDeleteModal(true);
    }
  
    const handleDelete = () => {
      onDeleteDevice(id);
      setIsOpenDeleteModal(false);
    }
  
    const handleConfirm = async () => {
      const options = {
        'option1': { identity },
        'option2': { identity, mes: selectedMonth, ano: selectedYear },
        'option3': { identity, cnpj: selectedCNPJ },
      };
  
      const params = options[selectedOption];
      if (params) {
        mutateDownloadAFD(params);
      }
      setIsOpenSaveModal(false);
    };
  
    const years = useMemo(() => {
      const currentYear = new Date().getFullYear();
      return Array.from({ length: 5 }, (_, i) => currentYear - i);
    }, []);
  
    const { mutate: mutateDownloadAFD } = useMutation({
      mutationFn: downloadAFD,
      onSuccess: (data) => {
        const fileName = `AFD BatidaONLINE - ${nomeComputador}.txt`;
        const setFile = (fileName, data) => {
          const pom = document.createElement('a');
          pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
          pom.setAttribute('download', fileName);
          pom.click();
        };
        setFile(fileName, data);
      },
      onError: (error) => {
        showError('Erro ao baixar AFD');
      }
    });
  
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        if (event.target.value !== 'option2') {
          setSelectedMonth('');
          setSelectedYear('');
        }
        if (event.target.value !== 'option3') {
          setSelectedCNPJ('');
        }
    };

    const radioOptions = [
      { value: 'option1', title: i18n.t('modal.optionsAFDAll') },
      { value: 'option2', title: i18n.t('modal.optionsAFDMonth'), children: <Option2 /> },
      { value: 'option3', title: i18n.t('modal.optionsAFDCNPJ'), children: <Option3 /> }
    ];
  
    
    const { data: dataCnpjs } = useQuery({
      queryKey: ['locations'],
      queryFn: getLocationsAndCnpj,
      refetchOnWindowFocus: false,
      retry: false
    });
  
    useEffect(() => {
      if (dataCnpjs) {
        setCnpjs(dataCnpjs.cnpjs.map(cnpj => ({ value: cnpj, title: cnpj })));
      }
    }, [dataCnpjs]);
  
    function Option2() {
      return (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl required fullWidth>
              <Select
                labelId="select-month-label"
                id="mes"
                title={i18n.t('modal.month')}
                items={[
                  { value: 1, title: i18n.t('modal.january') },
                  { value: 2, title: i18n.t('modal.february') },
                  { value: 3, title: i18n.t('modal.march') },
                  { value: 4, title: i18n.t('modal.april') },
                  { value: 5, title: i18n.t('modal.may') },
                  { value: 6, title: i18n.t('modal.june') },
                  { value: 7, title: i18n.t('modal.july') },
                  { value: 8, title: i18n.t('modal.august') },
                  { value: 9, title: i18n.t('modal.september') },
                  { value: 10, title: i18n.t('modal.october') },
                  { value: 11, title: i18n.t('modal.november') },
                  { value: 12, title: i18n.t('modal.december') }
                ]}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl required fullWidth>
              <Select
                labelId="select-year-label"
                id="ano"
                title={i18n.t('modal.year')}
                items={years.map(year => ({ value: year, title: year }))}
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
      );
    }
  
    function Option3() {
      return (
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Select
                items={cnpjs}
                id="cnpj"
                title={i18n.t('modal.CNPJ')}
                value={selectedCNPJ}
                onChange={(event) => { setSelectedCNPJ(event.target.value) }} />
            </FormControl>
          </Grid>
        </Grid>
      );
    }
  
    return (
        <>
            <div>
                <Link to={`/edit/${id}`}>
                    <Tooltip title={i18n.t('actions.edit')}>
                        <IconButton sx={{ padding: '6px' }} aria-label={`edit/${id}`}>
                            <Edit />
                        </IconButton>
                    </Tooltip>

                </Link>
                <Tooltip title={i18n.t('actions.save')}>
                    <IconButton sx={{ padding: '6px' }} onClick={() => setIsOpenSaveModal(true)}>
                        <Save />
                    </IconButton>
                </Tooltip>
                <Tooltip title={i18n.t('actions.archive')}>
                    <IconButton disabled={isShowArchived} sx={{ padding: '6px' }} aria-label={`delete/${id}`} onClick={onOpenDeleteModal}>
                        <Archive />
                    </IconButton>
                </Tooltip>

            </div>
            <AlertModal
                title={i18n.t('modal.titleAFD', { nome: nomeComputador })}
                visible={isOpenSaveModal}
                cancelAction={() => setIsOpenSaveModal(false)}
                cancelText={i18n.t('modal.modalButton.cancel')}
                buttonAction={handleConfirm}
                buttonText={i18n.t('modal.modalButton.download')}
                buttonColor="primary"
            >
                <div>
                    <StyledTypography>{i18n.t('modal.subtitleAFD')}</StyledTypography>
                    <Radio
                        values={radioOptions}
                        value={selectedOption}
                        onChange={handleOptionChange}
                        name="download-options"
                    />
                </div>
            </AlertModal>
            <DeviceDeleteAlert isOpen={isOpenDeleteModal} handleDelete={handleDelete} setIsOpenDeleteModal={setIsOpenDeleteModal} />
        </>
    );
  }
  
  export default ActionButtons;
