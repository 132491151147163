import { RadioGroup, Radio as RadioMui , FormControlLabel } from '@mui/material';
import React from 'react';

const Radio = ({values, value, ...props}) => {

    return (
        <RadioGroup
            defaultValue={props.defaultValue}
            name={props.name || "radio-buttons-group"}
            sx={props.sx}
            onChange={props.onChange}
            value={value}
        >
            {
                values.map((data, index) => {
                    return (
                    <div key={index}>
                        <FormControlLabel key={index} value={data.value} control={<RadioMui />} label={data.title}  disabled={props.disabled}/>
                        {value === data.value ? data.children : <></> }
                    </div>
                    )
                }
            )}
           
        </RadioGroup>
    )

};

export default Radio;