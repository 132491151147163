import { styled } from '@mui/system';
import { Link } from '@mui/material';

export const Container = styled('div')({
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
    flexDirection: "column",
    backgroundColor: 'white',
});

export const BreadcrumbsContainer = styled('div')({
    marginBottom: 2,
});

export const TitleTypography = styled('h1')({
    fontFamily: "Fira Sans",
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: 'var(--ahg-waterloo-darken1)',
    lineHeight: 'normal',
    letterSpacing: '0em',
    textAlign: 'left',
});

export const BreadcrumbLink = styled(Link)({
    fontWeight: 600,
    fontSize: 12,
    cursor: 'pointer',
    color: 'var(--ahg-waterloo-lighten1)',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
});

export const ActualBreadcrumb = styled('span')({
    fontWeight: 600,
    fontSize: 12,
    color: 'var(--ahg-primary-base)',
});

export const ButtonsContainer = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    gap: 24,
});

export const HeaderItems = styled('div')({
    flexDirection: "row",
    display: "flex",
    justifyItems: "center",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 32
});
