import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import i18n from '../../helpers/i18n';

const FilterStatus = ({...props}) => {
    return (
        <FormControl style={{ marginLeft: 16, marginTop: 24 }}>
            <RadioGroup
                row
                name="filter-status-buttons-group"
                onChange={(ev) => props.setFilterStatus(ev.target.value)}
                value={props.filterStatus}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                        fontFamily: 'Open Sans',
                        color: 'var(--ahg-waterloo-darken1)'
                    }
                }}
            >
                <FormControlLabel value="ativo" control={<Radio />} label={i18n.t('filter.active')} />
                <FormControlLabel style={{ marginLeft: 32 }} value="inativo" control={<Radio />} label={i18n.t('filter.inactive')} />
            </RadioGroup>
        </FormControl>
    );
}

export default FilterStatus;