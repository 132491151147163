import React from 'react';
import { PrimaryButton, Modal, ModalTitle, ModalBody, ModalFooter, TextButton } from './styles';

const AlertModal = ({ 
    title = '', 
    children,
    cancelAction = null, 
    cancelText = '', 
    buttonAction = () => {}, 
    buttonText = '', 
    buttonColor = 'primary', 
    visible = false 
}) => {

    const handleButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (typeof buttonAction === 'function') {
            buttonAction();
        } else {
            console.warn('AlertModal: buttonAction is not a function', buttonAction);
        }
    };

    // Direct button component instead of using RenderButton function
    const actionButton = (
        <PrimaryButton
            variant="contained"
            color={buttonColor === 'error' ? 'error' : 'primary'}
            onClick={handleButtonClick}
            style={{ 
                pointerEvents: 'auto',
                cursor: 'pointer',
                minWidth: '100px'
            }}
        >
            {buttonText}
        </PrimaryButton>
    );

    return (
        <Modal 
            open={visible}
            onClick={(e) => e.stopPropagation()}
        >
            <ModalTitle>{title}</ModalTitle>
            <ModalBody dividers>{children}</ModalBody>
            <ModalFooter>
                {cancelAction && (
                    <TextButton 
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            cancelAction();
                        }}
                    >
                        {cancelText}
                    </TextButton>
                )}
                {actionButton}
            </ModalFooter>
        </Modal>
    );
};

export default AlertModal;