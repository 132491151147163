import React from 'react';
import Router from './routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorProvider } from './components/SnackbarError/ErrorContext';
import SnackbarError from './components/SnackbarError/SnackbarError';

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ErrorProvider>
          <SnackbarError />
          <Router />
        </ErrorProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;