import React, { useState } from 'react';
import { CopyAll } from '@mui/icons-material';
import {StyledIconButton, truncate} from './styles';

const CopyButton = ({ value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async (value) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      } else {
        fallbackCopyTextToClipboard(value);
      }
    } catch (err) {
      console.error('Erro ao copiar:', err);
      fallbackCopyTextToClipboard(value);
    }
  };

  const fallbackCopyTextToClipboard = (value) => {
    const textArea = document.createElement('textarea');
    textArea.value = value;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <div>
      <StyledIconButton
        onClick={() => handleCopy(value)}
        aria-label={`copy/${value}`}
        copied={copied ? 1 : 0}
      >
        <span style={truncate}>
          {copied ? 'Copiado!' : value}
        </span>
        {copied === false && <CopyAll fontSize="small" />}
      </StyledIconButton>
    </div>
  );
};

export default CopyButton;