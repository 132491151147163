import AlertModal from '../AlertModal';
import { Typography } from '../Layout';

export default function DeviceUpdatedAlert({ isOpen , onClose}) {
    return (
        <AlertModal
            title="Dispositivo editado com sucesso"
            buttonText='Ok, Entendi'
            children={<>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Parabéns!</Typography>
                <Typography variant="body1" sx={{ fontWeight: 'regular', fontStyle: 'normal' }}>O dispositivo foi <strong>editado</strong> com sucesso e já está pronto para uso.</Typography></>}
            buttonAction={onClose}
            visible={isOpen} />
    )
}