import React from 'react';
import { Checkbox, Button } from '../Layout';
import InfoTooltip from '../InfoTooltip';
import { TitleH3, TitleSpan, gridItemStyle, checkboxStyle } from '../CreateDeviceContent/styles';
import i18n from '../../helpers/i18n';
import { Grid } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CoppyAllIcon from '@mui/icons-material/CopyAll';

async function copyLink(company) {
    await navigator.clipboard.writeText(`https://app.ahgora.com.br/novabatidaonline/?defaultDevice=${company}`)
}


function BreakDeviceFlag({ formik, variant = 'create', deviceArchived }) {
    return (
        <div style={checkboxStyle}>
            <Checkbox
                label={i18n.t('pages.newDevice.breakDeviceActivate')}
                checked={formik.values.break_device}
                onChange={(event) => formik.setFieldValue('break_device', event.target.checked)}
                disabled={deviceArchived}
            />
            <InfoTooltip tooltip={i18n.t('pages.newDevice.breakDeviceActivateDescription')} />
        </div>
    );
}
function DefaultComputerFlag({ formik, variant = 'create', deviceArchived }) {
    return (
        <>
            <div style={checkboxStyle}>
                <Checkbox
                    disabled={variant === 'edit' || deviceArchived}
                    label={i18n.t('pages.newDevice.defaultDevice')}
                    checked={formik.values.default_computer}
                    onChange={(event) => formik.setFieldValue('default_computer', event.target.checked)}

                />
                <InfoTooltip tooltip={i18n.t('pages.newDevice.defaultDeviceDescription')} />
            </div>

            {formik.values.default_computer && variant === 'edit' && (
                <div style={{ display: 'flex', flexDirection: 'column', width: '250px', gap: '8px', justifyContent: 'start', paddingLeft: '40px' }}>
                    <Button
                        type="outlined"
                        color="primary"
                        startIcon={<CoppyAllIcon />}
                        onClick={(e) => {
                            e.preventDefault();
                            copyLink(formik.values.company)
                        }} >
                        {i18n.t('pages.editDevice.copyLink')}
                    </Button>

                </div>
            )}
        </>
    );
}
function TimezoneFilterFlag({ formik , deviceArchived}) {
    return (
        <div style={checkboxStyle}>
            <Checkbox
                disabled={deviceArchived}
                label={i18n.t('pages.newDevice.timezoneFilter')}
                checked={formik.values.timezone_filter}
                onChange={(event) => formik.setFieldValue('timezone_filter', event.target.checked)}
            />
            <InfoTooltip tooltip={i18n.t('pages.newDevice.timezoneFilterDescription')} />
        </div>
    );
}
function EnablePunchesLogsFlag({ formik, deviceArchived }) {
    return (
        <div style={checkboxStyle}>
            <Checkbox
                disabled={deviceArchived}
                label={i18n.t('pages.newDevice.logsPunch')}
                checked={formik.values.enable_punches_logs}
                onChange={(event) => formik.setFieldValue('enable_punches_logs', event.target.checked)}
            />
            <InfoTooltip tooltip={i18n.t('pages.newDevice.logsPunchDescription')} />
        </div>
    );
}
function DownloadPunchFlag({ formik, variant = 'create', deviceArchived }) {
    return (
        <>

            {variant === 'create' && (
                <div style={checkboxStyle}>
                    <Checkbox
                        label={i18n.t('pages.newDevice.downloadPunch')}
                        checked={formik.values.enable_punch_backup}
                        onChange={(event) => formik.setFieldValue('enable_punch_backup', event.target.checked)}
                    />
                    <InfoTooltip tooltip={i18n.t('pages.newDevice.downloadPunch')} />
                </div>
            )}

            {variant === 'edit' && formik.values.enable_punch_backup && (
                <>
                    <div style={{ ...checkboxStyle }}>
                        <Checkbox
                            disabled={deviceArchived}
                            label={i18n.t('pages.newDevice.downloadPunch')}
                            checked={formik.values.enable_punch_backup}
                            onChange={(event) => formik.setFieldValue('enable_punch_backup', event.target.checked)}
                        />
                        <InfoTooltip tooltip={i18n.t('pages.newDevice.downloadPunch')} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '250px', gap: '8px', justifyContent: 'start', paddingLeft: '40px' }}>
                        <Button
                            type="contained"
                            color="primary"
                            startIcon={<DownloadIcon />}
                            onClick={() => console.log('Download')}>
                            Download
                        </Button>
                    </div>
                </>
            )}
        </>
    );
}


function PasswordPunchFlag({ formik, deviceArchived }) {
    return (
        <div style={checkboxStyle}>
            <Checkbox
                disabled={deviceArchived}

                label={i18n.t('pages.newDevice.punchRegistrationPassword')}
                checked={formik.values.enable_password_punch}
                onChange={(event) => formik.setFieldValue('enable_password_punch', event.target.checked)}
            />
            <InfoTooltip tooltip={i18n.t('pages.newDevice.punchRegistrationPasswordDescription')} />
        </div>
    );
}
function EnableAutoUpdateFlag({ formik, deviceArchived }) {
    return (
        <div style={checkboxStyle}>
            <Checkbox
                disabled={deviceArchived}

                label={i18n.t('pages.newDevice.automaticUpdate')}
                checked={formik.values.enable_auto_update}
                onChange={(event) => formik.setFieldValue('enable_auto_update', event.target.checked)}
            />
            <InfoTooltip tooltip={i18n.t('pages.newDevice.automaticUpdate')} />
        </div>
    );
}
function TripleValidationFlag({ formik, deviceArchived }) {
    return (
        <div style={checkboxStyle}>
            <Checkbox
                disabled={deviceArchived}

                label={i18n.t('pages.newDevice.tripleValidation')}
                checked={formik.values.triple_validation}
                onChange={(event) => formik.setFieldValue('triple_validation', event.target.checked)}
            />
            <InfoTooltip tooltip={i18n.t('pages.newDevice.tripleValidationDescription')} />
        </div>
    );
}

export default function ControllerSection({ formik, variant = 'create', permissions, deviceArchived }) {

    return (
        <>
            {formik.values.deviceType === 'ahgora_online_device' && (
                <Grid style={gridItemStyle} item xs={12} md={6}>
                    <TitleH3>
                        {i18n.t('pages.newDevice.controllers')} <TitleSpan>{i18n.t('pages.newDevice.optional')}</TitleSpan>
                    </TitleH3>
                    {permissions?.dispositivo_pausa && (
                        <BreakDeviceFlag formik={formik} variant={variant} deviceArchived={deviceArchived} />
                    )}
                    <DefaultComputerFlag formik={formik} variant={variant} deviceArchived={deviceArchived}/>
                    <TimezoneFilterFlag formik={formik} deviceArchived={deviceArchived}/>
                    <EnablePunchesLogsFlag formik={formik} deviceArchived={deviceArchived}/>
                    <DownloadPunchFlag formik={formik} variant={variant} deviceArchived={deviceArchived}/>
                </Grid>
            )}
            {formik.values.deviceType === 'ahgora_multi_device' && (
                <Grid style={gridItemStyle} item xs={12} md={6}>
                    <TitleH3>
                        {i18n.t('pages.newDevice.controllers')} <TitleSpan>{i18n.t('pages.newDevice.optional')}</TitleSpan>
                    </TitleH3>
                    {permissions?.dispositivo_pausa && (
                        <BreakDeviceFlag formik={formik} variant={variant} deviceArchived={deviceArchived}/>
                    )}
                    <PasswordPunchFlag formik={formik} deviceArchived={deviceArchived}/>
                    <EnableAutoUpdateFlag formik={formik} deviceArchived={deviceArchived}/>
                    <TimezoneFilterFlag formik={formik} deviceArchived={deviceArchived}/>
                    <TripleValidationFlag formik={formik} deviceArchived={deviceArchived}/>
                    <EnablePunchesLogsFlag formik={formik} deviceArchived={deviceArchived}/>
                    <DownloadPunchFlag formik={formik} variant={variant} deviceArchived={deviceArchived}/>
                </Grid>
            )}
            {formik.values.deviceType === 'biometry_device' && (
                <Grid style={gridItemStyle} item xs={12} md={6}>
                    <TitleH3>
                        {i18n.t('pages.newDevice.controllers')} <TitleSpan>{i18n.t('pages.newDevice.optional')}</TitleSpan>
                    </TitleH3>
                    <PasswordPunchFlag formik={formik} deviceArchived={deviceArchived}/>
                    <EnableAutoUpdateFlag formik={formik} deviceArchived={deviceArchived}/>
                    <TimezoneFilterFlag formik={formik} deviceArchived={deviceArchived}/>
                    <EnablePunchesLogsFlag formik={formik} deviceArchived={deviceArchived}/>
                    <DownloadPunchFlag formik={formik} variant={variant} deviceArchived={deviceArchived}/>
                </Grid>
            )}
            {formik.values.deviceType === 'my_ahgora_device' && (
                <Grid style={gridItemStyle} item xs={12} md={6}>
                    <TitleH3>
                        {i18n.t('pages.newDevice.controllers')} <TitleSpan>{i18n.t('pages.newDevice.optional')}</TitleSpan>
                    </TitleH3>
                    {permissions?.dispositivo_pausa && (
                        <BreakDeviceFlag formik={formik} variant={variant} deviceArchived={deviceArchived}/>
                    )}
                </Grid>
            )}
        </>
    );
}
